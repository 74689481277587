/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import Header from "./header"
import Newsletter from "./newsletter"
import Footer from "./footer"
// import "./layout.css"
import CookieConsent from "react-cookie-consent"
import ReactGA from "react-ga"
const Layout = ({ children, lang }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ParallaxProvider>
      <Header lang={lang} siteTitle={data.site.siteMetadata.title} />
      <div className="container">
        <main>{children}</main>
      </div>
      <Newsletter />
      <Footer />
      <CookieConsent
      location="top"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="allow-ga"
      enableDeclineButton={true}
      containerClasses="banner"
      buttonClasses="banner__btn"
      declineButtonClasses="banner__decline"
      contentClasses="banner__content"
      onAccept={() => {
        ReactGA.initialize(data.site.siteMetadata.trackingId)
      }}
    >
      We use cookies and Google Analytics to make this website as interesting as
      possible for you. Do you agree? (You can revoke this decision at any time)
    </CookieConsent>
    </ParallaxProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
