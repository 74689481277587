// import { Link } from "gatsby"
import React from "react"
// import Img from "gatsby-image"
const Footer = () => {

  return (
    <footer className="footer">
      <div className="footer__wrapper container">
        <div className="footer__left">
        <img
          src={require("../images/logo-header-cropped.svg")}
          alt="Hiive Logo"
        />
        <div className="footer__copyright">© Hiive Experience Hotels</div>
        </div>
      
        <div className="footer__legal">
          <a href="/privacy">Privacy Policy</a>
          <a href="/imprint">Imprint</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
