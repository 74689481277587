import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

const EmailForm = () => {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState("")
  const [error, setError] = useState("")
  const [checked, setChecked] = useState(false)
  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email)
      .then(data => {
        
        setSuccess(
          "Thanks for your interest. You will receive an email for confirmation."
        )
      })
      .catch(e => {
        setError("Unfortunately there was an error, please try again later.")
   
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  const toggleCheck = () => {
    setChecked(!checked)
  }

  return (
    <form onSubmit={handleSubmit} className="">
      <div className="newsletter__form">
        {success ? (
          <div>
            <p className="newsletter__hint">{success}</p>
          </div>
        ) : (
          <div>
            <input
              placeholder="Your Email address"
              name="email"
              type="email"
              onChange={handleEmailChange}
              required
            />
            <button type="submit">Subscribe</button>
            {error && (
              <div>
                <p className="newsletter__hint">{error}</p>
              </div>
            )}
            <div className="newsletter__privacy">
              <input
                type="checkbox"
                checked={checked}
                onChange={toggleCheck}
                name="privacy"
                id="privacy"
                required
              />
              <label htmlFor="privacy">
                I have read and accepted the{" "}
                <a href="/privacy" target="_blank">
                  Privacy policy
                </a>{" "}
                and agree to the processing and storage of my data.
              </label>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

export default EmailForm
