// import { Link } from "gatsby"
import React, {  useEffect, useState }  from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Parallax } from "react-scroll-parallax"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      headerFluidImage: file(relativePath: { eq: "header-landscape.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    let currentScrollPos = window.pageYOffset;
    let stickyPos = 200;
    if(!sticky && currentScrollPos > stickyPos) {

      setSticky(true);
    }
    if (currentScrollPos <= stickyPos) {
      setSticky(false);
    }
  
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);



  return (
    <>
      <div className={`header header--sticky${sticky ? ' is--sticky' : ''}`}>
        <div className="container">
          {/* <ul className="header__anchorlist header__anchorlist--sticky">
            <li>
              <button onClick={() => scrollTo("#home")}>Home</button>
            </li>
            <li>
              <button onClick={() => scrollTo("#our-mentality")}>
                Our Mentality
              </button>
            </li>
            <li>
              <button onClick={() => scrollTo("#stay-with-us")}>
                Stay with us
              </button>
            </li>
          </ul> */}
          <img
          onClick={() => scrollTo("#home")}
            src={require("../images/logo-header-cropped.svg")}
            alt="Hiive Logo Cropped"
            className="header__logo header__logo--sticky"
          />
        </div>{" "}
      </div>
      <header className="header" id="home">
        <div className="container">
          {/* <ul className="header__anchorlist">
            <li>
              <button onClick={() => scrollTo("#home")}>Home</button>
            </li>
            <li>
              <button onClick={() => scrollTo("#our-mentality")}>
                Our Mentality
              </button>
            </li>
            <li>
              <button onClick={() => scrollTo("#stay-with-us")}>
                Stay with us
              </button>
            </li>
          </ul> */}

          <img
            src={require("../images/logo-header.svg")}
            alt="Hiive Logo"
            className="header__logo fade-in"
          />
          <div className="header__hero">
            <div>
              <Img
                className="header__hero-image"
                fluid={data.headerFluidImage.childImageSharp.fluid}
                alt="Hero Image"
              />
            </div>
            <div className="header__hero-organic-wrapper">
              <Parallax
                className="header__hero-organic1 fade-in-9"
                y={["0", "0"]}
                tagOuter="div"
              >
                <img src={require("../images/organic1.svg")} alt="Organic" />
              </Parallax>
              <Parallax
                className="header__hero-organic2 fade-in-9"
                y={[0, 0]}
                tagOuter="div"
              >
                <img src={require("../images/organic2.svg")} alt="Organic" />
              </Parallax>
            </div>

            <Parallax className="fade-in" y={["-30", "0"]} tagOuter="h1">
              Your next level hotel experience starts{" "}
              <span onClick={() => scrollTo("#stay-with-us")}>here</span>.
            </Parallax>
          </div>

          {/* <img srcset={data.dataJson.header.hero.childImageSharp.fluid.srcSet} alt="test" /> */}
        </div>
      </header>
    </>
  )
}

export default Header
