// import { Link } from "gatsby"
import React from "react"
import EmailForm from "./emailform"
import { Parallax } from "react-scroll-parallax"

// import Img from "gatsby-image"
const Newsletter = () => {


  return (
    <div id="stay-with-us" className="newsletter">
      <div className="container">
        <Parallax
          className="newsletter__organic fade-in"
          y={[0, -50]}
          tagOuter="div"
        >
          <img src={require("../images/organic3.svg")} alt="Organic" />
        </Parallax>
        <div className="newsletter__content">
        <h4>Stay with us</h4>
        <h3>
          Become part of our creation process and be the first to experience all
          updates.
        </h3>
        <EmailForm />
        </div>
      </div>
    </div>
  )
}

export default Newsletter
